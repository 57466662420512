@font-face {
font-family: '__IBMPlex_73d18a';
src: url(/_next/static/media/94a9db23ba1fcddc-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__IBMPlex_73d18a';
src: url(/_next/static/media/baf9a19aa194eeee-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__IBMPlex_73d18a';
src: url(/_next/static/media/4079bccff76d7140-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__IBMPlex_73d18a';
src: url(/_next/static/media/896217949212f42a-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__IBMPlex_Fallback_73d18a';src: local("Arial");ascent-override: 107.29%;descent-override: 41.04%;line-gap-override: 0.00%;size-adjust: 101.13%
}.__className_73d18a {font-family: '__IBMPlex_73d18a', '__IBMPlex_Fallback_73d18a'
}

